import { createRouter, createWebHistory } from 'vue-router'
import Devis from "../views/Devis.vue";
import SignIn from '@/views/SignIn.vue';
import LogIn from '@/views/LogIn.vue';
import NewHome from '@/views/NewHome.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import("../views/Home.vue"),
      meta: {
        showMenu: true,
        requiresAuth: true
      }
    },
    {
      path: '/devis/:id',
      name: 'Devis',
      component: Devis,
      meta: {
        showMenu: true,
        requiresAuth: true
      }
    },
    {
      path: '/newdevis',
      name: 'newDevis',
      component: Devis,
      meta: {
        showMenu: true,
        requiresAuth: true
      }
    },
    {
      path: '/signin',
      name: 'signIn',
      component: SignIn,
      meta: {
        showMenu: false,
        requiresAuth: false,
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LogIn,
      meta: {
        showMenu: false,
        requiresAuth: false
      }
    },

    {
      path: '/newhome',
      name: 'newHome',
      component: NewHome,
      meta: {
        showMenu: false,
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next)=>{
  
    const isAuthenticated = !!localStorage.getItem('token')
  
    if(to.meta.requiresAuth && !isAuthenticated){
      next({path:'/login'});
    } else {
      next();
    }

})

export default router
