<script>
import DropDownMenu from './DropDownMenu.vue';
import NavButton from './NavButton.vue';

export default {
    components: {
        DropDownMenu,
        NavButton
    },
    data() {
    return {
        isFiltersVisible: false,

        OptionsMenu1: ['Client 1', 'Client 2', 'Client 3'],
        OptionsMenu2: ['Sent', 'Draft', 'Signed'],
        OptionsMenu3: ['All time', 'Last 7 Days', 'Last 30 Days', 'This year'],
    };
  },

  methods: {
    toggleFilters() {
      this.isFiltersVisible =!this.isFiltersVisible;
    }
  }
}
</script>

<template>
    <div class="filterContainer">
        <NavButton :message="'Afficher les filtres'" :icon="['fas', 'filter']" @click="toggleFilters" />

        <div v-if="isFiltersVisible" class="filterMenus">
                        <DropDownMenu :options="OptionsMenu1" :buttonProps="{ message: 'Client', icon: ['fas', 'chevron-down'] }" />

            <DropDownMenu :options="OptionsMenu2" :buttonProps="{ message: 'Status', icon: ['fas', 'chevron-down'] }" />

            <DropDownMenu :options="OptionsMenu3" :buttonProps="{ message: 'Date', icon: ['fas', 'chevron-down'] }" />
        </div>


    </div>
</template>



<style scoped>
.filterContainer {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    justify-content: space-around;
}

.filterMenus {
    display: flex;
    justify-content: center;
}
</style>