<script>
import IconComponent from "../IconComponent.vue";

export default {
    components: {
        IconComponent,
    },

    props: {
        message: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        style: {
            type: Object,
            default: () => ({
                size: 16,
                color: "white",
                backgroundColor: "blue",
            }),
        },
    },
};
</script>

<template>
    <div>
        <button :style="buttonStyles" class="btn">
            <!-- <IconComponent :name="icon" :size="style.size" :color="style.color" /> -->
            <font-awesome-icon :icon="icon" />
            <span v-if="message" class="btn-text">{{ message }}</span>
            <span v-if="placeholder" class="btn-placeholder">{{ placeholder }}</span>
        </button>
    </div>
</template>

<style scoped>
.btn {
    display: inline-flex;
    align-items: center;
    width: 200px;
    height: 50px;

    justify-content: center;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.btn-text {
    margin-left: 8px;
}

.btn-placeholder {
    font-size: 12px;
    color: grey;
    margin-left: 8px;
}
</style>
