<script>
import DropDownMenu from '@/components/HomePage/NavBar/DropDownMenu.vue';
import NavButton from '@/components/HomePage/NavBar/NavButton.vue';
import Filter from './Filter.vue';
export default {
    components: {
        NavButton,
        DropDownMenu,
        Filter,

    }
}
</script>

<template>
    <Filter/>
    <NavButton />
    <NavButton />
    <NavButton />
    <NavButton />
</template>

<style scoped></style>