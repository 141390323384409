<script>
import NavButton from './NavButton.vue';
export default {

    components: {

        NavButton,
    },
    props: {
        optionName: {
            type: String,
            default: ","
        },
        options: {
            type: Array,
            required: true,
        },
        buttonProps: {
            type: Object,
            default: () => ({
                message: '',
                icon: [''],
            }),
        },
    },


    data() {
        return {
            isDropDownMenuVisible: false,
        }
    },

    methods: {
        toggleDropdown() {
            this.isDropDownMenuVisible = !this.isDropDownMenuVisible;
        }
    }
}
</script>

<template>
    <div class="dropDownMenu">
        <NavButton :message="buttonProps.message" :icon="buttonProps.icon" @click="toggleDropdown" />
        <ul v-if="isDropDownMenuVisible" class="dropDownMenuOptions">
            <li v-for="(option, index) in options" :key="index">
                <a href="#">{{ option }}</a>
            </li>
        </ul>
    </div>
</template>



<style scoped>
.dropDownMenu {
    color: black;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    width: 250px;
}

.MenuBtnTitle {
    background-color: whitesmoke;
    font-size: 16px;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: black;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.MenuBtnTitle:hover {
    background-color: #ddd;

}

.dropDownMenuOptions {
    width: 100%;

    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: whitesmoke;
}

.dropDownMenuOptions li {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.dropDownMenuOptions li:hover {
    background-color: #ddd;
}

a {}

.fa {
    color: black;
    font-size: 20px;
    margin-left: 8px;
}
</style>