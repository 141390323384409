<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, far);


export default {
    components: {
        FontAwesomeIcon
    },
    // data() {
    //     return {
    //         icons: {
    //             send: ['fas', 'paper-plane'],
    //             download: ['fas', 'file-pdf'],
    //             edit: ['fas', 'pen'],
    //             delete: ['fas', 'trash-can'],
    //             clone: ['fas', 'clone']
    //         }
    //     };
    // },

    props: {
        size: {
            type: Object,
            default: () => ({
                width,
                height,
            })
        },
        color: {
            type: String,
            default: 'red'
        }
    },
    computed: {
        iconStyle() {
            return {
                width: this.size.width,
                height: this.size.height,
                color: this.color
            };
        }
    }

}


</script>

<template>
    <!-- <font-awesome-icon :icon="icons[iconName]" :style="iconStyle" /> -->
    <div class="icon-container">
        <font-awesome-icon :style="iconStyle" />
    </div>

</template>


<style scoped>
.icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: brown;
}
</style>