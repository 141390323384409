<script>
import Actions from "@/components/HomePage/Actions.vue";

export default {
    components: {
        Actions,
    },

    data() {
        return {
            devisList: [
                {
                    titreDeDevis: "test",
                    id: 1,
                    client: "John Doe",
                    status: "En cours",
                    date: "01/01/2022",
                },
                {
                    titreDeDevis: "test",
                    id: 2,
                    client: "Jane Smith",
                    status: "En cours",
                    date: "02/02/2022",
                },
                {
                    titreDeDevis: "test",
                    id: 3,
                    client: "Bob Johnson",
                    status: "Terminé",
                    date: "03/03/2022",
                },
                {
                    titreDeDevis: "test",
                    id: 4,
                    client: "Alice Davis",
                    status: "En cours",
                    date: "04/04/2022",
                },
                {
                    titreDeDevis: "test",
                    id: 5,
                    client: "Charlie Wilson",
                    status: "Terminé",
                    date: "05/05/2022",
                },
                {
                    titreDeDevis: "test",
                    id: 6,
                    client: "Charlie Wilson",
                    status: "Terminé",
                    date: "05/05/2022",
                },
                {
                    titreDeDevis: "test",
                    id: 7,
                    client: "Charlie Wilson",
                    status: "Terminé",
                    date: "05/05/2022",
                },
                {
                    titreDeDevis: "test",
                    id: 8,
                    client: "Charlie Wilson",
                    status: "Terminé",
                    date: "05/05/2022",
                },
                {
                    titreDeDevis: "test",
                    id: 9,
                    client: "Charlie Wilson",
                    status: "Terminé",
                    date: "05/05/2022",
                },
            ],
            visibleActionsMenuId: null,
        };
    },

    methods: {
        toggleMenu(menuId) {
            if (this.visibleActionsMenuId === menuId) {
                this.visibleActionsMenuId = null;
            } else {
                this.visibleActionsMenuId = menuId;
            }
        },
    }

};
</script>

<template>
    <div class="devis-container">
        <table>
            <thead class="thead">
                <tr>
                    <th>Titre du devis</th>
                    <th>N° devis</th>
                    <th>Nom du Client</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="devis in devisList" :key="devis.id">
                    <td>{{ devis.titreDeDevis }}</td>
                    <td>{{ devis.id }}</td>
                    <td>{{ devis.client }}</td>
                    <td>{{ devis.status }}</td>
                    <td>{{ devis.date }}</td>
                    <td>
                        <Actions 
                            :devisId="devis.id" 
                            :isMenuVisible="visibleActionsMenuId === devis.id"
                            :menuId="devis.id" 
                            @toggle-menu="toggleMenu" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
.devis-container {
    width: 80%;
    margin: 0 auto;
    border: 1px solid #dcdcdc;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.thead {
    background-color: black;
    height: 65px;

    /* border-radius: 10px 10px 0px 0px; */
}

th {
    color: white;
    padding: 10px;
    border: none;
}

td {
    border: none;
    padding: 10px;
}

tbody tr {
    border-bottom: 1px solid #dcdcdc;
    height: 60px;
}

tbody tr:nth-child(odd) {
    background-color: #f9fafa;
}

tbody tr:hover {
    background-color: #f5f5f5;
}
</style>
