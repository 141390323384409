<script>
import DevisMap from '@/components/HomePage/DevisMap.vue';
import Actions from '@/components/HomePage/Actions.vue';
import CompleteNavBar from '@/components/HomePage/NavBar/CompleteNavBar';

export default {
    components: {
        CompleteNavBar,
        DevisMap,
        Actions,
    },
}

</script>

<template>

    <div>
        <div class="navButton">
            <CompleteNavBar />
        </div>
        <DevisMap />
        <Actions />
    </div>

</template>

<style scoped>
.navButton {
    display: flex;
    justify-content: center;
}
</style>
